import Head from 'next/head';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import Script from 'next/script';
import CarLogo from '../src/assets/images/logos/vip29-car-logo.png';
import NProgress from '../src/components/NProgress';
import '../styles/style.scss';

function MyApp({ Component, pageProps }) {
    const Layout = Component.layout || (({ children }) => <>{children}</>);
    return (
        <>
            <Head>
                <title>{process.env.APP_NAME}</title>
                <meta
                    name={process.env.APP_NAME}
                    content="Generated by create next app"
                />
                <link rel="icon" href={CarLogo.src} />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
                />
            </Head>
            <RecoilRoot>
                <NProgress />
                <Layout>
                    <Component {...pageProps} />
                    <ToastContainer
                        position="top-right"
                        autoClose={8000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        draggable={false}
                        pauseOnVisibilityChange
                        closeOnClick
                        pauseOnHover
                    />
                </Layout>
            </RecoilRoot>
            {(process.env.NODE_ENV === 'development' ||
                process.env.TEST_MODE === 'YES') && (
                <Script
                    src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
                    onLoad={() => {
                        const vConsole = new window.VConsole();
                    }}
                />
            )}
        </>
    );
}

export default MyApp;
